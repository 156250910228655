$web-font-path: 'data:text/css;base64,';
@import "bootstrap.min.css";
@import "bootstrap-extend.min.css";
@import "site.min.css";
@import "teal.min.css";
@import '~@fortawesome/fontawesome-free/css/all.css';
@import "../fonts/web-icons/web-icons.min.css";


.r-0{
    right: 0 !important;
}
body#success{
    background-color: #f1f4f5 !important;
}
.vertical-align-top{
    vertical-align:top;
}